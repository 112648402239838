import React from "react";
import BookingForm from "../components/BookingForm";
import About from "../components/About";
import PopularRoutes from "../components/PopularRoutes";
import Cars from "../components/Cars";
import OurServices from "../components/Home/OurServices";
import OneWayServices from "../components/Home/OneWayServices";
import TopRoutes from "../components/Home/TopRoutes";
import PricePlan from "../components/Home/PricePlan";
import PopularOutstations from "../components/PopularOutstations";
import ChooseUs from "../components/Home/ChooseUs";

const Home = () => {
  return (
    <>
      <BookingForm />
      <About />
        <PricePlan />
        <PopularOutstations />
       {/* <PopularRoutes /> */}
      {/* <OneWayServices /> */}
      <OurServices />
      {/* <TopRoutes /> */}
      <ChooseUs />
    </>
  );
};

export default Home;
