import React from "react";
import img1 from "../../assets/images/ser1.png";
import img2 from "../../assets/images/ser2.png";
import img3 from "../../assets/images/ser3.png";
import img4 from "../../assets/images/ser4.png";
const OurServices = () => {
  return (
    <div>
      <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
          Our Service
        </h4>
        <h3 className="mt-4 font-semibold text-3xl">
          "Aj Cabs: The Best Choice for Drop Taxi Services in South India"
        </h3>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <p>
            <b className="pr-2">Drop Taxi Service Across Tamil Nadu :</b>
            We cover all major cities, including Chennai, Coimbatore, Madurai, Trichy, and more.
          </p>
          <p>
            <b className="pr-2">Pondicherry Drop Service : </b>
            Enjoy a seamless trip to the beautiful coastal town of Pondicherry.          </p>
            <p>
            <b className="pr-2">Bangalore Drop Taxi :  </b>
            Travel hassle-free from Tamil Nadu to Bangalore, with flexible timing and safe driving.                </p>
        </div>
      </div>
      {/* <div className="  px-0 sm:px-4 md:px-8 lg:px-16 bg-[#f5f5f5]  py-3 md:py-5 mt-4 md:mt-6 lg:mt-8">
        <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2  text-center">
          <div className=" flex flex-col gap-y-1">
            <img src={img1} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Easy Online Booking
            </p>
            <p>Pickup and Drop on time</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img2} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
            Professional Drivers
            </p>
            <p>Well Trained years of experience</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img3} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
            Variety of Prime Brands
            </p>
            <p>Benz - BMW - Audi</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img4} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
            Secure Online Payment
            </p>
            <p>24 /7 Customer Support</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default OurServices;
