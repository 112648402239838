import React from 'react'
import about from "../assets/about.jpg";
import { FaPhone } from 'react-icons/fa6';
const About = () => {
    return (
        <div  id='about'>
            <div className='container py-5 mx-auto px-4 md:px-8 lg:px-16'>
                <div className='block md:flex py-5 gap-x-20 items-center'>
                    <div className='w-full md:w-1/2'>
                        <div className=' relative'>
                            <img src={about} alt="about" className='mx-auto w-[500px] car_outline' />
                        </div>
                    </div>
                    <div className='mt-10 md:mt-0 px-3 w-full md:w-1/2'>
                        <h4 className=' text-lg font-medium primary-color'>About Us</h4>

                        <p className='text-black opacity-60 md:text-lg mt-5'>At AJ Cabs, we specialize in offering reliable and affordable one-way drop taxi services across Tamil Nadu, Pondicherry, and Bangalore. Whether you're traveling for business, leisure, or a family trip, our goal is to provide a comfortable and hassle-free ride that gets you to your destination on time, every time.</p>
                        <div className=' mt-5'>
                        <a
                href="tel:+919363731710"
                className=" rounded-md th-btn-reverse hover:bg-black transition-all ease-linear px-3 py-2"
              >
                <span className=" flex gap-x-2 items-center justify-center">
                  <FaPhone size={17} /> +91 9363731710
                </span>
              </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About